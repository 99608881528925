import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Card, {
	CardHeader,
	CardLabel,
	CardTitle,
	CardBody,
	CardActions,
} from '../../bootstrap/Card';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import OffCanvas, {
	OffCanvasHeader,
	OffCanvasTitle,
	OffCanvasBody,
} from '../../bootstrap/OffCanvas';
import Textarea from '../../bootstrap/forms/Textarea';
import { getUserType } from '../../../services/common.service';
import { toasts } from '../../../services/toast.service';
import {
	addReminderDetails,
	deleteReminderDetails,
	getReminderDetails,
	updateReminderDetails,
} from '../../../services/reminder.service';
import { getLicenseKey } from '../../../services/application.settings';
import AuthContext from '../../../contexts/authContext';
import { showLoader } from '../../../services/loader.services';
import DeleteComponents from '../../../common/components/DeleteComponents';
import AlertService from '../../../services/AlertService';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Alert from '../../bootstrap/Alert';
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';

const AddReminder = () => {
	useEffect(() => {
		getReminder();
		getUserTypeList();
	}, []);

	const { userAccountId } = useContext(AuthContext);
	const [addReminderOffCanvas, setAddReminderOffCanvas] = useState(false);
	const [editReminderOffCanvas, setEditReminderOffCanvas] = useState(false);
	const [staffTypeId, setStaffTypeId] = useState<any>('');
	const [reminderData, setReminderData] = useState<any>([]);
	const colors = ['info', 'success', 'warning', 'secondary', 'primary'];
	const [studentReminderData, setStudentReminderData] = useState<any>([]);
	const [staffReminderData, setStaffReminderData] = useState<any>([]);
	const [parentReminderData, setParentReminderData] = useState<any>([]);
	const [type, setType] = useState('');

	const [userTypeData, setUserTypeData] = useState<any>([]);

	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [studentNoDataMsg, setStudentNoDataMsg] = useState<any>('');
	const [staffNoDataMsg, setStaffNoDataMsg] = useState<any>('');
	const [parentNoDataMsg, setParentNoDataMsg] = useState<any>('');

	const reminderForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			title: '',
			staffDetailsId: '',
		},
		validate: (values) => {
			const errors: {
				title?: string;
				staffDetailsId?: string;
			} = {};
			if (!values.title) {
				errors.title = 'Required';
			}
			return errors;
		},
		validateOnChange: true,
		onSubmit: () => {
			addReminderSubmit();
		},
	});

	const editReminderForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			title: '',
			reminderDetailsId: '',
		},
		validate: (values) => {
			const errors: {
				title?: string;
				reminderDetailsId?: number;
			} = {};
			if (!values.title) {
				errors.title = 'Required';
			}
			return errors;
		},
		validateOnChange: true,
		onSubmit: () => {
			updateReminderSubmit();
		},
	});

	const selectUserType = (e: any) => {
		let staffTypeId = e;
		setStaffTypeId(staffTypeId);
	};

	function getUserTypeList() {
		getUserType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.userType;
					if (data != undefined) {
						const userTypeData = [{ userTypeId: 0, userType: 'All Staff' }];
						data.forEach((obj: { userTypeId: number; userType: string }) => {
							let userType = userTypeData.push(obj);
						});
						setUserTypeData(userTypeData);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setUserTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getReminder() {
		getReminderDetails(
			0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.reminderDetails;
					if (data != undefined) {
						for (let i = 0; i < data.length; i++) {
							data[i].colors = colors[Math.floor(Math.random() * colors.length)];
						}
						console.log(data);

						let studentReminder = data.filter((item: any) => item.userTypeId == 9);
						setStudentReminderData(studentReminder);
						setStudentNoDataMsg(studentReminder == '' ? 'No Data Found' : '');
						console.log(studentReminderData);

						let staffReminder = data.filter(
							(item: any) => item.userTypeId != 9 && item.userTypeId != 10,
						);
						setStaffReminderData(staffReminder);
						setStaffNoDataMsg(staffReminder == '' ? 'No Data Found' : '');
						console.log(staffReminder);

						let parentReminder = data.filter((item: any) => item.userTypeId == 10);
						setParentReminderData(parentReminder);
						setParentNoDataMsg(parentReminder == '' ? 'No Data Found' : '');
						console.log(parentReminderData);
						setReminderData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setReminderData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function onActivate(i: any) {
		if (i != undefined) {
			let selectedReminder = i;
			setEditReminderOffCanvas(true);

			editReminderForm.setValues({
				title: selectedReminder.title,
				reminderDetailsId: selectedReminder.reminderDetailsId,
			});
		}
	}
	function setReminderDetails() {
		return {
			title: reminderForm.values.title,
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId,
			userTypeId: type == 'student' ? 9 : type == 'parent' ? 10 : staffTypeId?.value,
		};
	}

	function setEditReminderDetails() {
		return {
			reminderDetailsId: editReminderForm.values.reminderDetailsId,
			title: editReminderForm.values.title,
			staffDetailsId: 1,
			licenseKey: 5566,
			userTypeId: type == 'student' ? 9 : type == 'parent' ? 10 : staffTypeId?.value,
		};
	}

	function addReminderSubmit() {
		showLoader(true);
		if (reminderForm.isValid) {
			let reminderTypePostData = setReminderDetails();
			addReminderDetails(
				reminderTypePostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						getReminder();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (reminderForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function updateReminderSubmit() {
		showLoader(true);
		if (editReminderForm.isValid) {
			let updateReminderPost = setEditReminderDetails();
			updateReminderDetails(
				updateReminderPost,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						getReminder();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (editReminderForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function deleteCasteSubmit() {
		showLoader(true);
		// if (editReminderForm.isValid) {
		let updateReminderPost = setEditReminderDetails();
		deleteReminderDetails(
			updateReminderPost,
			(response) => {
				const data = response.data;
				if (data.success == true) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: 'success' });
					setIsOpen(true);
					closeAndReset();
					getReminder();
				} else if (data.success == false) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: 'error' });
					setIsOpen(true);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					showLoader(false);
					setAlertStatus({ message: errorDescription, type: 'error' });
					setIsOpen(true);
				}
			},
			(error) => {
				showLoader(false);
				setAlertStatus({ message: error, type: 'error' });
				setIsOpen(true);
			},
		);
		// } else if (editReminderForm.isValid == false) {
		//     showLoader(false)
		//     setAlertStatus({ message: 'Please fill all the details!', type: "error" });
		//     setIsOpen(true);
		// }
	}

	function closeAndReset() {
		setAddReminderOffCanvas(false);
		setEditReminderOffCanvas(false);
		setIsDeleteOpen(false);
		reminderForm.resetForm();
		editReminderForm.resetForm();
		setStaffTypeId('');
	}

	return (
		<PageWrapper title='Reminder'>
			<SubHeader>
				<SubHeaderLeft>
					<span className='fs-5'>Reminder</span>
					<SubheaderSeparator />
				</SubHeaderLeft>
			</SubHeader>
			<Page>
				<div className='row h-100'>
					<div className='col-lg-6'>
						<Card
							stretch
							className='overflow-hidden border border-light'
							shadow='md'
							tag='form'
							noValidate
							style={{ height: '350px' }}>
							<CardHeader borderSize={1}>
								<CardLabel icon='Chat' iconColor='info'>
									<CardTitle tag='div' className='h5'>
										Staff Reminder
									</CardTitle>
								</CardLabel>
								<CardActions className='d-print-none'>
									<Button
										color='primary'
										icon='Add'
										isLight
										onClick={() => {
											setAddReminderOffCanvas(true);
											setType('staff');
										}}>
										Add New
									</Button>
								</CardActions>
							</CardHeader>

							<CardBody isScrollable>
								{staffReminderData != '' ? (
									<div className='row'>
										{staffReminderData.map((i: any) => (
											<>
												<div key={i.reminderDetailsId} className='col-11'>
													<Alert color={i.colors} icon='Info'>
														{i.title} - {i.userType}
													</Alert>
												</div>
												<div className='d-print-none col-1 mt-3'>
													<Button
														color='dark'
														isLight
														icon='Edit'
														aria-label='Edit'
														onClick={() => {
															onActivate(i);
															setType('staff');
														}}
													/>
												</div>
											</>
										))}
									</div>
								) : (
									<div className='d-flex justify-content-center text-uppercase fw-bold text-danger'>
										{staffNoDataMsg}
									</div>
								)}
							</CardBody>
						</Card>
					</div>
					<div className='col-lg-6'>
						<Card
							stretch
							className='overflow-hidden border border-light'
							shadow='md'
							tag='form'
							noValidate
							style={{ height: '350px' }}>
							<CardHeader borderSize={1}>
								<CardLabel icon='Chat' iconColor='info'>
									<CardTitle tag='div' className='h5'>
										Student Reminder
									</CardTitle>
								</CardLabel>
								<CardActions className='d-print-none'>
									<Button
										color='primary'
										icon='Add'
										isLight
										onClick={() => {
											setAddReminderOffCanvas(true);
											setType('student');
										}}>
										Add New
									</Button>
								</CardActions>
							</CardHeader>

							<CardBody isScrollable>
								{studentReminderData != '' ? (
									<div className='row'>
										{studentReminderData.map((i: any) => (
											<>
												<div key={i.reminderDetailsId} className='col-11'>
													<Alert color={i.colors} icon='Info'>
														{i.title}
													</Alert>
												</div>
												<div className='d-print-none col-1 mt-2'>
													<Button
														color='dark'
														isLight
														icon='Edit'
														aria-label='Edit'
														onClick={() => {
															onActivate(i);
															setType('student');
														}}
													/>
												</div>
											</>
										))}
									</div>
								) : (
									<div className='d-flex justify-content-center text-uppercase fw-bold text-danger'>
										{studentNoDataMsg}
									</div>
								)}
							</CardBody>
						</Card>
					</div>
					<div className='col-lg-6'>
						<Card
							stretch
							className='overflow-hidden border border-light'
							shadow='md'
							tag='form'
							noValidate
							style={{ height: '350px' }}>
							<CardHeader borderSize={1}>
								<CardLabel icon='Chat' iconColor='info'>
									<CardTitle tag='div' className='h5'>
										Parent Reminder
									</CardTitle>
								</CardLabel>
								<CardActions className='d-print-none'>
									<Button
										color='primary'
										icon='Add'
										isLight
										onClick={() => {
											setAddReminderOffCanvas(true);
											setType('parent');
										}}>
										Add New
									</Button>
								</CardActions>
							</CardHeader>

							<CardBody isScrollable>
								{parentReminderData != '' ? (
									<div className='row'>
										{parentReminderData.map((i: any) => (
											<>
												<div key={i.reminderDetailsId} className='col-11'>
													<Alert color={i.colors} icon='Info'>
														{i.title}
													</Alert>
												</div>
												<div className='d-print-none col-1 mt-2'>
													<Button
														color='dark'
														isLight
														icon='Edit'
														aria-label='Edit'
														onClick={() => {
															onActivate(i);
															setType('parent');
														}}
													/>
												</div>
											</>
										))}
									</div>
								) : (
									<div className='d-flex justify-content-center text-uppercase fw-bold text-danger'>
										{parentNoDataMsg}
									</div>
								)}
							</CardBody>
						</Card>
					</div>
				</div>

				<OffCanvas
					setOpen={setAddReminderOffCanvas}
					isOpen={addReminderOffCanvas}
					titleId='addReminderOffCanvas'
					isBodyScroll
					placement='end'
					tag='form'
					noValidate
					onSubmit={reminderForm.handleSubmit}>
					<OffCanvasHeader setOpen={setAddReminderOffCanvas} onClick={closeAndReset}>
						<OffCanvasTitle id='addReminderOffCanvas'>Reminder Creation</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row g-4'>
							{type == 'staff' ? (
								<div className='col-12'>
									<FormGroup id='staffTypeId' label='User Type'>
										<SearchableSelect
											ariaLabel='Type'
											onChange={selectUserType}
											value={staffTypeId}
											list={userTypeData.map((data: any) => ({
												value: data.userTypeId,
												label: data.userType,
											}))}
										/>
									</FormGroup>
								</div>
							) : null}
							<div className='col-12'>
								<FormGroup id='title' label='Title'>
									<Textarea
										autoComplete=''
										onChange={reminderForm.handleChange}
										onBlur={reminderForm.handleBlur}
										value={reminderForm.values.title}
										isValid={reminderForm.isValid}
										isTouched={reminderForm.touched.title}
										invalidFeedback={reminderForm.errors.title}
									/>
								</FormGroup>
							</div>
						</div>
					</OffCanvasBody>
					<div className='row m-0'>
						<div className='col-12 p-3'>
							<Button
								color='info'
								icon='Save'
								className='w-100'
								type='submit'
								isDisable={!reminderForm.isValid && !!reminderForm.submitCount}>
								Save
							</Button>
						</div>
					</div>
				</OffCanvas>

				<OffCanvas
					setOpen={setEditReminderOffCanvas}
					isOpen={editReminderOffCanvas}
					titleId='editReminderOffCanvas'
					isBodyScroll
					placement='end'
					tag='form'
					noValidate
					onSubmit={editReminderForm.handleSubmit}>
					<OffCanvasHeader setOpen={setEditReminderOffCanvas} onClick={closeAndReset}>
						<OffCanvasTitle id='editReminderOffCanvas'>Update Reminder</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row g-4'>
							{type == 'staff' ? (
								<div className='col-12'>
									<FormGroup id='staffTypeId' label='User Type'>
										<SearchableSelect
											ariaLabel='Type'
											onChange={selectUserType}
											value={staffTypeId}
											list={userTypeData.map((data: any) => ({
												value: data.userTypeId,
												label: data.userType,
											}))}
										/>
									</FormGroup>
								</div>
							) : null}

							<div className='col-12'>
								<FormGroup id='title' label='Title'>
									<Textarea
										autoComplete=''
										onChange={editReminderForm.handleChange}
										onBlur={editReminderForm.handleBlur}
										value={editReminderForm.values.title}
										isValid={editReminderForm.isValid}
										isTouched={editReminderForm.touched.title}
										invalidFeedback={editReminderForm.errors.title}
									/>
								</FormGroup>
							</div>
						</div>
					</OffCanvasBody>

					<div className='row'>
						<div className='d-flex justify-content-center position-absolute top-65 start-50 translate-middle'>
							<DeleteComponents
								isDeleteOpen={isDeleteOpen}
								setIsDeleteOpen={setIsDeleteOpen}
								deleteFunction={deleteCasteSubmit}
							/>
						</div>
						<div className='col-6 position-absolute bottom-0 start-0 p-3'>
							<Button
								color='danger'
								className='w-100'
								icon='Delete'
								onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
								Delete
							</Button>
						</div>
						<div className='col-6 position-absolute bottom-0 end-0 p-3'>
							<Button
								color='info'
								icon='Save'
								type='submit'
								className='w-100'
								isDisable={
									!editReminderForm.isValid && !!editReminderForm.submitCount
								}>
								{' '}
								Update
							</Button>
						</div>
					</div>
				</OffCanvas>
			</Page>
			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
		</PageWrapper>
	);
};

export default AddReminder;
