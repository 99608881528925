import React, { useEffect, useState } from 'react';
import Card, {
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../../../components/bootstrap/Card';
import { toasts } from '../../../../services/toast.service';
import { useNavigate } from 'react-router-dom';
import { TableLoader } from '../../../../services/loader.services';
import { getCircularDetails, getReminderDetails } from '../../../../services/dashboard.service';
import Alert, { AlertHeading } from '../../../../components/bootstrap/Alert';

const DashboardReminder = () => {

    useEffect(() => {
        getReminderList();
    }, []);

    const [dataStatus, setDataStatus] = useState(false);
    const [reminderData, setReminderData] = useState<any>([]);
    const colors = ['info', 'success', 'warning', 'secondary', 'primary'];

    const navigate = useNavigate();

    function getReminderList() {
        getReminderDetails(10,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.reminderDetails;
                    if (data != undefined) {
                        setDataStatus(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].color =
                                colors[Math.floor(Math.random() * colors.length)];
                        }
                        setReminderData(data);
                    } else {
                        setDataStatus(false);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setDataStatus(true);
                    // toasts(response.data.message, 'Error');
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataStatus(true);
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                setDataStatus(true);
                toasts(error, 'Error');
            },
        );
    }

    return (

        <Card stretch data-tour='list'>
            <CardHeader borderSize={1} >
                <CardLabel icon='list' iconColor='info' className='col-lg-12 '>
                    <CardTitle tag='div' className='h5 '>
                        Reminder
                    </CardTitle>
                </CardLabel>
            </CardHeader>
            <CardBody style={{ height: '50vh' }}>
                {!dataStatus ? 
                    <TableLoader /> :
                    reminderData.map((i: any) => (
                        <Alert icon='VerifiedUser' isLight color={i.color} className='flex-nowrap'>
                            <td>{i.title}</td>
                        </Alert>
                    ))
                }
            </CardBody>
        </Card >

    );
};

export default DashboardReminder;


