import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getReminderDetails = (userTypeId: any,success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,
) => api.get(getAPIURL() + `getReminderDetails/${getLicenseKey}/${userTypeId}`,
	(response) => {
		success(response);
	},
	(error) => {
		failure('getReminderDetails - ' + error.message);
		console.log('error caught in service : getReminderDetails');
	},
);

export const addReminderDetails = (reminderTypePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

	api.post(getAPIURL() + `addReminderDetails`, reminderTypePostData,
		response => {
			success(response)
		},
		error => {
			failure(error.message)
			console.log('error caught in service : addReminderDetails')
		}
	);

export const updateReminderDetails = (updateReminderPost: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

	api.post(getAPIURL() + `updateReminderDetails`, updateReminderPost,
		response => {
			success(response)
		},
		error => {
			failure(error.message)
			console.log('error caught in service : updateReminderDetails')
		}
	);

export const deleteReminderDetails = (updateReminderPost: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

	api.post(getAPIURL() + `deleteReminderDetails`, updateReminderPost,
		response => {
			success(response)
		},
		error => {
			failure(error.message)
			console.log('error caught in service : deleteReminderDetails')
		}
	);