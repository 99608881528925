import React, { useContext, useEffect, useState } from 'react';
import Card, { CardBody, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import classNames from 'classnames';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../../../layout/SubHeader/SubHeader';
import useDarkMode from '../../../../hooks/useDarkMode';
import { getScheduleDetailsByDate } from '../../../../services/timeTableService';
import { toasts } from '../../../../services/toast.service';
import AuthContext from '../../../../contexts/authContext';
import { convertDateToEpoch, getCurrentDateAndTime } from '../../../../services/common.service';
import HumansWebp from '../../../../assets/img/scene2.webp';
import Humans from '../../../../assets/img/scene2.png';
import Icon from '../../../../components/icon/Icon';

const StaffPeriodSchedule = () => {

	useEffect(() => {
		getScheduleDetailsList();
	}, []);

	const [scheduleData, setScheduleData] = useState<any>([]);
	const { themeStatus, darkModeStatus } = useDarkMode();
	const { userAccountId, userTypeId } = useContext(AuthContext);

	const [dayOrder, setDayOrder] = useState<any>('')
	const [dayId, setDayId] = useState<any>('')
	const [dataStatus, setDataStatus] = useState<any>(false)

	function getScheduleDetailsList() {

		let currentDate = getCurrentDateAndTime('date')
		let dateInMilliSec = convertDateToEpoch(currentDate)

		getScheduleDetailsByDate(
			userTypeId,
			userAccountId,
			dateInMilliSec,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.scheduleDetailsByDate[0].period;
					if (data != undefined) {
						setDataStatus(true)
						setDayId(response.data.data.scheduleDetailsByDate[0].dayId);
						setDayOrder(response.data.data.scheduleDetailsByDate[0].dayOrderName);
						setScheduleData(data);
						for (let i = 0; i < data.length; i++) {
							data[i].periodCount = data[i].periodName[data[i].periodName.length - 1];
						}
					} else {
						toasts('Undefined Data', 'Error');
						setDataStatus(true)
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setScheduleData([]);
					setDataStatus(true)
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setDataStatus(true)
				}
			},
			(error) => {
				toasts(error, 'Error');
				setDataStatus(true)
			},
		);
	}

	return (
		<Page container='fluid'>
			{dataStatus ? <div style={{height: '50vh'}}>
				{scheduleData != '' && dayId > 0 && dayId != 7 ? (
					<>
						<div className='col-12'>
							<div className='row'>
								{scheduleData.map((period: any) => (
									<div className='col-4' key={period.periodDetailsId}>
										<Card stretch shadow={'md'} className="border border-light">
											<CardBody>
												<div className='row g-3 align-items-center'>
													<div className='col d-flex align-items-center'>
														<div className='flex-shrink-0'>
															<div
																className='ratio ratio-1x1'
																style={{ width: 52 }}>
																<div
																	className={classNames(
																		'rounded-2',
																		'd-flex align-items-center justify-content-center',
																		{
																			'bg-l10-info':
																				!darkModeStatus,
																			'bg-lo25-info':
																				darkModeStatus,
																		},
																	)}>
																	<span className='text-info fs-3 fw-bold'>
																		{period.periodCount}
																	</span>
																</div>
															</div>
														</div>
														<div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
															<div>
																<small className='fw-bold'>
																	{period.paperName}
																</small>
																<div className='text-success'>
																	<span className='fw-bold'>
																		{period.standardName} - {period.sectionName}
																	</span>
																</div>
																<div className='text-info'>
																	<small>
																		<span className='text-black-50 fw-bold'>
																			{period.startTime} - {period.endTime}
																		</span>
																	</small>
																</div>
															</div>
														</div>
													</div>
												</div>
											</CardBody>
										</Card>
									</div>
								))}{' '}
							</div>
						</div>
					</>
				) :
					<div className='row'>
						<div className='col-12 d-flex justify-content-center'>
							{scheduleData != '' && (dayId == 0 || dayId == 7) ?
								<div className='row'>
									<div className="col-12" style={{ height: '10vh' }}></div>
									<div className='col-12 d-flex justify-content-center'>
										<Icon icon='CalendarToday' size={'10x'} />
									</div>
									<div className='fs-4 fw-bold text-center text-uppercase text-danger'>
										Holiday
									</div>
								</div> :
								<div className='row'>
									<div className='col-12 d-flex justify-content-center'>
										<img
											srcSet={HumansWebp}
											src={Humans}
											alt='Humans'
											style={{ height: '40vh' }}
										/>
									</div>
									<div className='fs-4 fw-bold text-center text-uppercase text-danger'>
										Schedule Not Allocated
									</div>
								</div>
							}
						</div>
					</div>
				} </div> : null
			}
		</Page>
	);
};

export default StaffPeriodSchedule;
