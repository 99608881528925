import React, { useContext, useEffect, useState } from 'react';
import { useTour } from '@reactour/tour';
import useDarkMode from '../../../hooks/useDarkMode';
import { demoPagesMenu } from '../../../menu';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import Page from '../../../layout/Page/Page';
import ThemeContext from '../../../contexts/themeContext';
import TotalPresentAbsenteesReport from './dashboard-component/TotalPresentAbsenteesReport';
import TodayFeeCollection from './dashboard-component/TodayFeeCollection';
import FeesCollection from './dashboard-component/FeesCollection';
import AttendanceNotSubmitted from './dashboard-component/AttendanceNotSubmitted';
import PendingFees from './dashboard-component/PendingFees';
import AuthContext from '../../../contexts/authContext';
import TodayAbsentCount from './dashboard-component/TodayAbsentCount';
import StaffPeriodSchedule from './dashboard-component/StaffPeriodSchedule';
import { toasts } from '../../../services/toast.service';
import { getScheduleDetailsByDate } from '../../../services/timeTableService';
import { convertDateToEpoch, getCurrentDateAndTime } from '../../../services/common.service';
import ActivityLog from './dashboard-component/ActivityLog';
import ClassToper from './dashboard-component/ClassToper';
import FailedStudents from './dashboard-component/FailedStudents';
import StudentFeeTransaction from './dashboard-component/StudentFeeTransaction';
import ComplaintList from '../../../components/general/administration/ComplaintList';
import Card, { CardActions, CardHeader, CardLabel, CardSubTitle, CardTitle } from '../../../components/bootstrap/Card';
import StaffLeaveReqestList from './dashboard-component/StaffLeaveReqestList';
import StaffAttendanceStatus from '../../../components/charts/StaffAttendanceStatus';
import ParentDashboard from './ParentDashboard';

const DashboardPage = () => {

	useEffect(() => {
		getScheduleDetailsList();
	}, []);

	const { mobileDesign } = useContext(ThemeContext);
	const { userAccountId, userData, userTypeId } = useContext(AuthContext);

	console.log(userTypeId);
	

	/**
	 * Tour Start
	 */

	const { setIsOpen } = useTour();
	useEffect(() => {
		if (localStorage.getItem('tourModalStarted') !== 'shown' && !mobileDesign) {
			setTimeout(() => {
				setIsOpen(true);
				localStorage.setItem('tourModalStarted', 'shown');
			}, 7000);
		}
		return () => { };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { themeStatus } = useDarkMode();

	const [dayOrder, setDayOrder] = useState<any>('')

	function getScheduleDetailsList() {

		let currentDate = getCurrentDateAndTime('date')
		let dateInMilliSec = convertDateToEpoch(currentDate)

		getScheduleDetailsByDate(
			userTypeId,
			userAccountId,
			dateInMilliSec,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.scheduleDetailsByDate[0].period;
					if (data != undefined) {
						setDayOrder(response.data.data.scheduleDetailsByDate[0].dayOrderName);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setDayOrder('');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	console.log(userTypeId);
	console.log(userData);


	return (
		<PageWrapper title={demoPagesMenu.sales.subMenu.dashboard.text}>
			<SubHeader>
				<SubHeaderLeft>
					<span className='fs-5'>Today Overview</span>
					<SubheaderSeparator />
					<strong className='fs-5'>Hi, <span className='text-primary'>{`${userTypeId == 10 ? userData.guardian : userData.fullname}`}</span> !</strong>
				</SubHeaderLeft>
			</SubHeader>
			<Page container='fluid'>
				<div className='row' >

					{/* ----------------For Admin DashBoard----------------*/}
					{userTypeId == 1 ?
						<>
							<div className='col-xl-6'>
								<TodayFeeCollection />
							</div>
							<div className='col-xl-6'>
								<TodayAbsentCount />
							</div>
							{/* <div className='col-xl-6'>
								<FeesCollection />
							</div>
							<div className='col-xl-6 ' >
								<TotalPresentAbsenteesReport />
							</div>
							<div className='col-xl-6'>
								<PendingFees />
							</div>
							<div className='col-xl-6'>
								<AttendanceNotSubmitted />
							</div> */}
							<div className='col-xl-6'>
								<StudentFeeTransaction />
							</div>
							<div className='col-xl-6'>
								<ActivityLog />
							</div>
							<div className='col-xl-6'>
								<ClassToper />
							</div>
							<div className='col-xl-6'>
								<FailedStudents />
							</div>
							<div className='col-xl-6'>
								<ComplaintList type={'dashboard'} />
							</div>
							<div className='col-xl-6'>
								<StaffLeaveReqestList />
							</div>
						</>
						: null
					}

					{userTypeId == 7 ?
						<>
							<div className='row'>
								<div className='col-xl-6'>
									<div className=' col-xl-12'>
										<TodayFeeCollection />
									</div>
									<div className=' col-xl-12'>
										<FeesCollection />
									</div>
								</div>
								<div className='col-xl-6'>
									<div className='row col-xl-12 h-100'>
										<PendingFees />
									</div>
								</div>
							</div>
							<div className='col-xl-6'>
								<ClassToper />
							</div>
							<div className='col-xl-6'>
								<FailedStudents />
							</div>
						</>
						: null
					}

					{userTypeId == 2 || userTypeId == 3 || userTypeId == 4 || userTypeId == 5 ?
						<>
							<div className='col-6'>
								<div className=' col-12'>
									<TodayAbsentCount />
								</div>
								<div className=' col-12'>
									{/* <AttendanceNotSubmitted /> */}
									<StaffAttendanceStatus staffDetailsId={userAccountId} height={197} />
								</div>
							</div>
							<div className='col-6'>
								<ActivityLog />
							</div>
							<div className='col-xl-6'>
								<ClassToper />
							</div>
							<div className='col-xl-6'>
								<FailedStudents />
							</div>
						</>
						: null
					}

					{/* ----------------For Staff DashBoard----------------*/}
					{userTypeId == 6 ?
						<>
							<div className='col-8'>
								<Card stretch className='rounded-1 border border-light'>
									<CardHeader borderSize={1} className='mt-2'>
										<CardLabel icon='MenuOpen' iconColor='info'>
											<CardTitle>Schedule</CardTitle>
										</CardLabel>
										<CardActions><div className="text-success mt-1"><b>{dayOrder}</b></div></CardActions>
									</CardHeader>
									<StaffPeriodSchedule />
								</Card>
							</div>
							<div className='col-4'>
								<ActivityLog />
							</div>
							<div className='col-xl-6'>
								<ClassToper />
							</div>
							<div className='col-xl-6'>
								<FailedStudents />
							</div>
						</> : null
					}

					{userTypeId == 10 ?
						<>
							<ParentDashboard />
						</> : null
					}

				</div>
			</Page>
		</PageWrapper>
	);
};

export default DashboardPage;
