import React, { useContext, useEffect, useRef, useState } from 'react';
import Card, { CardHeader, CardBody } from '../../../../components/bootstrap/Card';
import { getCircularDetails } from '../../../../services/dashboard.service';
import { toasts } from '../../../../services/toast.service';
import AuthContext from '../../../../contexts/authContext';
import { getSchoolDetails } from '../../../../services/school.service';
import Icon from '../../../../components/icon/Icon';
import { useParams } from 'react-router-dom';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Button from '../../../../components/bootstrap/Button';
import { useReactToPrint } from 'react-to-print';
import CollegeLogo from '../../../../assets/logos/logo.jpg';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../../layout/SubHeader/SubHeader';

const CircularDetails = () => {
	useEffect(() => {
		getCircularList();
		getSchool();
	}, []);

	const { circularDetailsId } = useParams();

	const { userTypeId, userAccountId } = useContext(AuthContext);
	const [dataStatus, setDataStatus] = useState(false);
	const [circularData, setCircularData] = useState<any>([]);
	const [schoolDetailsData, setSchoolDetailsData] = useState<any>([]);

	const componentRef = useRef(null);

	function getCircularList() {
		getCircularDetails(
			userTypeId,
			circularDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.circularDetails;
					if (data != undefined) {
						setDataStatus(true);
						setCircularData(data);
					} else {
						setDataStatus(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataStatus(true);
					// toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	function getSchool() {
		getSchoolDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.schoolDetails[0];
					if (data != undefined) {
						setSchoolDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});
	return (
		<PageWrapper title={'Circular'}>
			<Page container='fluid'>
				<SubHeader className='border border-light'>
					<SubHeaderLeft>
						<Button
							color='info'
							isLink
							icon='ArrowBack'
							tag='a'
							to={`../dashboard`}>
							Back to List
						</Button>
						<SubheaderSeparator />
					</SubHeaderLeft>
				</SubHeader>
				<div className='row mt-2'>
					<div className='col-12 '>
						{circularData.map((i: any) => (
							<Card shadow={"md"} className='border border-light' key={i.circularDetailsId}>
								<CardBody>
									<table ref={componentRef}
										cellPadding={10}
										cellSpacing={10}
										width='100%'
										style={{ fontFamily: 'Times New Roman' }}>
										<tbody style={{ borderBottom: 'solid 1px black' }}>
											<tr>
												<td align='center' width='20%'>
													<img
														height='100px'
														width='100px'
														src={CollegeLogo}
													/>
												</td>
												<th
													className='text-center'
													align='center'
													style={{
														fontFamily: 'Bookman Old Style',
														width: '60%',
													}}>
													<h1 className='text-uppercase'>
														<b style={{ fontSize: '90%' }}>
															{schoolDetailsData.schoolName}
														</b>
													</h1>
													<p className='fs-6'>
														({schoolDetailsData.description})<br />
														{schoolDetailsData.address}
														<br />
														<Icon icon='Email' />{' '}
														{schoolDetailsData.website}{' '}
														<Icon icon='Phone' />{' '}
														{schoolDetailsData.phone}{' '}
														<Icon icon='PhoneAndroid' />{' '}
														{schoolDetailsData.cellNo}
													</p>
												</th>
												<td width='20%'></td>
											</tr>
										</tbody>
										<tbody>
											<tr>
												<th style={{ width: '24%' }}>
													<p style={{ float: 'left', fontSize: '142%' }}>
														Reference No : {i.referenceNo}
													</p>
												</th>
												<th className='text-center'>
													<h2 className='mt-5' style={{ fontFamily: 'Bookman Old Style' }}>
														<u>CIRCULAR</u>
													</h2>
													<br />
													<h2 className='text-uppercase' style={{ fontFamily: 'Bookman Old Style' }}>
														<u>{i.subject}</u>
													</h2>
												</th>
												<th style={{ width: '25%' }}>
													<p style={{ float: 'right', fontSize: '142%' }}>
														Date : {i.circularDate}
													</p>
												</th>
											</tr>
											<tr>
												<td
													width='100%'
													colSpan={4}
													className='fs-4'
													style={{ fontFamily: 'Bookman Old Style' }}>
													<div dangerouslySetInnerHTML={{ __html: i.content }} />
													<br />
													<br />
													<br />
													<label
														style={{
															float: 'right',
															marginRight: '20px',
														}}
														className='fw-bold'>
														PRINCIPAL
													</label>
												</td>
											</tr>
										</tbody>
									</table>
									<br />
									<br />
									<br />
								</CardBody>
							</Card>
						))}
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default CircularDetails;
