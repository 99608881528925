import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Card, { CardHeader, CardActions, CardBody } from '../../../bootstrap/Card';
import Icon from '../../../icon/Icon';
import CollegeLogo from '../../../../assets/logos/logo.jpg';
import signature from '../../../../assets/img/signature.png';
import seal from '../../../../assets/img/seal.png';
import { useReactToPrint } from 'react-to-print';
import Button from '../../../bootstrap/Button';

const FeeReceipt2 = () => {
	const componentRef = useRef(null);

	const data = [
		{ sr: 1, particulars: 'Computer Fees', amount: 200 },
		{ sr: 2, particulars: 'Term Fees', amount: 200 },
		{ sr: 3, particulars: 'Language Proficiency Class', amount: 300 },
		{ sr: 4, particulars: 'Tuition Fees', amount: 1300 },
	];
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<PageWrapper title='Fee Receipt2'>
			<Page container='fluid'>
				<div className='col-11 ms-5 d-flex justify-content-end'>
					<Button icon='Print' isLight color='primary' onClick={handlePrint}>
						Print
					</Button>
				</div>
				<CardBody ref={componentRef}>
					<div>
						<table cellPadding={10} cellSpacing={10} width='100%' style={{ fontFamily: 'Times New Roman' }} className='border border-dark'>
							<tbody style={{ borderBottom: 'solid 1px black' }}>
								<tr>
									<td align='center' width='20%'>
										<img height='100px' width='100px' src={CollegeLogo} />
									</td>
									<td colSpan={1} className='text-center' align='right' style={{ fontFamily: 'Bookman Old Style', width: '60%' }}>
										<h1 className='text-uppercase'>
											<b style={{ fontSize: '90%' }}>Surya International School</b>
										</h1>

										<p className='fs-6 fw-bold'>
											(Permanent Affiliated to NAAC, India)
											<br />
											Vettalam Rd,keeroor(v), Dindigul (TK), Dindigul, 624001,
											<br />
											<Icon icon='Email' />{' '}
											https://school-dev.bluebottless.com{' '}
											<Icon icon='Phone' /> 8428614298{' '}
											<Icon icon='PhoneAndroid' /> 7695869984
										</p>
									</td>
								</tr>
							</tbody>
							<tbody>
								<tr>
									<th style={{ width: '10%' }}></th>
									<th style={{ width: '15%' }} className='text-center'>
										<h2 className='mt-3' style={{ fontFamily: 'Bookman Old Style' }}>
											<u>FEES RECEIPT</u>
										</h2>
									</th>
									<th style={{ width: '5%' }}></th>
									<th>
										<p className='text-start mt-2 fs-5'>
											<strong>Date : </strong>
											{dayjs(new Date()).format('DD/MM/YYYY')}
										</p>
									</th>
								</tr>								
								<tr>
									<td colSpan={4} style={{ fontFamily: 'Bookman Old Style' }}>
										<table
											className='ms-2'
											style={{ width: '99%', borderCollapse: 'collapse' }}>
											<thead>
												<tr>
													<th style={{ width: '5%', padding: '8px', fontSize: '145%' }}>
														Name :
													</th>
													<th className='text-start' style={{ width: '23%', padding: '8px', fontSize: '135%' }}>
														<label style={{ width: '100%', borderBottom: 'solid rgb(61, 57, 57) 1px' }} className='text-muted text-center'>
															<b contentEditable='true' suppressContentEditableWarning={true}>MANOJ KUMAR</b>
														</label>
													</th>
													<th style={{ width: '15%', padding: '8px', fontSize: '145%' }}>
														Admission No :
													</th>
													<th style={{ width: '29%', padding: '8px', fontSize: '135%' }}>
														<label style={{ width: '100%', borderBottom: 'solid rgb(61, 57, 57) 1px' }} className='text-muted text-center'>
															<b contentEditable='true' suppressContentEditableWarning={true}>PC14102</b>
														</label>

													</th>
												</tr>
												<tr>
													<th style={{ width: '5%', padding: '8px', fontSize: '145%' }}>
														Class :
													</th>
													<th style={{ width: '23%', padding: '8px', fontSize: '135%' }}>
														<label style={{ width: '100%', borderBottom: 'solid rgb(61, 57, 57) 1px' }} className='text-muted text-center'>
															<b contentEditable='true' suppressContentEditableWarning={true}>III - B</b>
														</label>
													</th>
													<th style={{ width: '15%', padding: '8px', fontSize: '145%' }}>
														Amount in words :
													</th>
													<th style={{ width: '29%', padding: '8px', fontSize: '135%' }}>
														<label style={{ width: '100%', borderBottom: 'solid rgb(61, 57, 57) 1px' }} className='text-muted text-center'>
															<b contentEditable='true' suppressContentEditableWarning={true}>Two Thousand Only /-</b>
														</label>

													</th>
												</tr>
											</thead>
										</table>
									</td>
								</tr>

								<tr>
									<td colSpan={4} className='fs-4' style={{ fontFamily: 'Bookman Old Style' }}>
										<table
											className='ms-2'
											style={{ width: '99%', borderCollapse: 'collapse' }}>
											<thead>
												<tr className='fs-4'>
													<th
														style={{
															border: '1px solid black',
															padding: '8px',
														}}>
														S.No
													</th>
													<th
														style={{
															border: '1px solid black',
															padding: '8px',
														}}>
														Particulars
													</th>
													<th
														style={{
															border: '1px solid black',
															padding: '8px',
														}}>
														Amount
													</th>
												</tr>
											</thead>
											<tbody className='fs-4'>
												{data.map((item) => (
													<tr key={item.sr}>
														<td
															style={{
																border: '1px solid black',
																padding: '8px',
															}}>
															{item.sr}
														</td>
														<td
															style={{
																border: '1px solid black',
																padding: '8px',
															}}>
															{item.particulars}
														</td>
														<td
															style={{
																border: '1px solid black',
																padding: '8px',
															}}>
															{item.amount}
														</td>
													</tr>
												))}
												<tr>
													<td colSpan={2}></td>
													<td>
														<p className='text-start mt-2 fs-4'>
															<strong>Total :</strong> 2000
														</p>
													</td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
								<tr>
									<td colSpan={2}>
										<div className='col-12 ms-5'>
											<img
												style={{ borderRadius: '50%' }}
												src={signature}
												width='120'
												height='120'
											/>
											<br />
											<span className='fs-4 fw-bold ms-5 text-uppercase'>
												Seal
											</span>
										</div>
									</td>
									<td colSpan={2}>
										<div className='col-12'>
											<img
												style={{ borderRadius: '50%' }}
												src={seal}
												width='120'
												height='120'
											/>
											<br />
											<span className='fs-4 fw-bold ms-2 text-uppercase'>
												Signature
											</span>
										</div>
									</td>
								</tr>
							</tbody>
						</table>

						<br />
						<br />
						<br />
					</div>
				</CardBody>
			</Page>
		</PageWrapper>
	);
};

export default FeeReceipt2;
