import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const validateUserFromEmail = (validateUserPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `validateUserFromEmail`, validateUserPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : validateUserFromEmail')
        }
    );

export const validateStudent = (validateUserPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `validateStudent`, validateUserPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : validateStudent')
        }
    );

export const loginForStaff = (loginData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `loginForStaff`, loginData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : loginForStaff')
        }
    );

export const loginForStudent = (loginData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `loginForStudent`, loginData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : loginForStudent')
        }
    );
    export const validateParents = (validateUserPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

        api.post(getAPIURL() + `validateParents`, validateUserPostData,
            response => {
                success(response)
            },
            error => {
                failure(error.message)
                console.log('error caught in service : validateParents')
            }
        );
        
export const loginForParents = (loginData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `loginForParents`, loginData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : loginForParents')
        }
    );
export const getStaffLoginDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffLoginDetails/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffLoginDetails - " + error.message)
            console.log('error caught in service : getStaffLoginDetails')
        },
    );

export const addStaffLoginDetails = (addStaffloginPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStaffLoginDetails`, addStaffloginPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStaffLoginDetails')
        }
    );

export const updateStaffLoginDetails = (editStaffloginPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStaffLoginDetails`, editStaffloginPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStaffLoginDetails')
        }
    );

export const deleteStaffLoginDetails = (editStaffloginPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteStaffLoginDetails`, editStaffloginPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteStaffLoginDetails')
        }
    );

    export const validateAluminiStudent = (validateUserPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `validateAluminiStudent`, validateUserPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : validateAluminiStudent')
        }
    );

    export const loginForAluminiStudent = (loginData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `loginForAluminiStudent`, loginData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : loginForAluminiStudent')
        }
    );