import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useFormik } from 'formik';
import Icon from 'react-syntax-highlighter/dist/esm/languages/prism/icon';
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody, CardSubTitle } from '../../../components/bootstrap/Card';
import { TimelineItem } from '../../../components/extras/Timeline';
import { Timeline } from '../../../components/icon/material-icons';
import AuthContext from '../../../contexts/authContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { getCurrentDateAndTime, convertDateToEpoch, getAttendanceReportByStudentDetailsId } from '../../../services/common.service';
import { getStudentActivityLog } from '../../../services/dashboard.service';
import { getStudentTotalFeeCollection } from '../../../services/fee.service';
import { getScheduleDetailsByDate } from '../../../services/timeTableService';
import { toasts } from '../../../services/toast.service';
import ClassToper from './dashboard-component/ClassToper';
import Circular from './dashboard-component/Circular';
import DashboardEventList from './dashboard-component/DashboardEventList';
import DashboardReminder from './dashboard-component/DashboardReminder';


const ParentDashboard = () => {
    const { userAccountId, userTypeId } = useContext(AuthContext);

    useEffect(() => {
        const upto = getCurrentDateAndTime('date');
        const currentDate = new Date();
        // Subtract 7 days to get the start date
        const startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - 7);
        const formattedDate = startDate.toDateString();
        getActivityLogList(
            userAccountId,
            convertDateToEpoch(formattedDate),
            convertDateToEpoch(upto),
        );
        getScheduleDetailsList()
        getStudentTotalFeeCollectionList(userAccountId)
        getAttendanceReport(userAccountId)
    }, []);

    const { themeStatus, darkModeStatus } = useDarkMode();
    const [scheduleData, setScheduleData] = useState<any>([]);
    const [studentTotalFeeCollectionData, setStudentTotalFeeCollectionData] = useState<any>([]);
    const [balanceAmt, setBalanceAmt] = useState<number>(0);
    const [attendanceReport, setAttendanceReport] = useState<any>('');

    const staffScheduleForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            date: getCurrentDateAndTime('date'),
        },
        validate: (values) => {
            const errors: {
                date?: string;
            } = {};

            if (!values.date) {
                errors.date = 'Required';
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const [dayOrder, setDayOrder] = useState<any>('');
    const [dayId, setDayId] = useState<any>('');
    const [dataStatus, setDataStatus] = useState<any>(false);
    const [type, setType] = useState('year');

    function getScheduleDetailsList() {
        let scheduleDate = staffScheduleForm.values.date;
        let dateInMilliSec = convertDateToEpoch(scheduleDate);

        getScheduleDetailsByDate(
            userTypeId,
            userAccountId,
            dateInMilliSec,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.scheduleDetailsByDate[0].period;
                    if (data != undefined) {
                        setDataStatus(true);
                        setScheduleData(data);
                        setDayId(response.data.data.scheduleDetailsByDate[0].dayId);
                        setDayOrder(response.data.data.scheduleDetailsByDate[0].dayOrderName);
                        for (let i = 0; i < data.length; i++) {
                            data[i].periodCount = data[i].periodName[data[i].periodName.length - 1];
                        }
                    } else {
                        toasts('Undefined Data', 'Error');
                        setDataStatus(true);
                    }
                } else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setScheduleData([]);
                    setDataStatus(true);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                    setDataStatus(true);
                }
            },
            (error) => {
                toasts(error, 'Error');
                setDataStatus(true);
            },
        );
    }

    function getStudentTotalFeeCollectionList(studentDetailsId: number) {
        getStudentTotalFeeCollection(
            studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentTotalFeeCollection;
                    if (data != undefined) {
                        setStudentTotalFeeCollectionData(data);
                        setBalanceAmt(
                            data[0].semFees_netPayable +
                            data[0].prevSemFees_netPayable +
                            data[0].busFees_netPayable +
                            data[0].prevBusFees_netPayable +
                            data[0].extraFees_netPayable,
                        );
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function getAttendanceReport(studentDetailsId: number) {
        getAttendanceReportByStudentDetailsId(
            studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.attendanceReportByStudentDetailsId[0];
                    if (data != undefined) {
                        setAttendanceReport({
                            series: [data.presentPerentage],
                            options: {
                                chart: {
                                    type: 'radialBar',
                                    offsetY: -20,
                                    sparkline: {
                                        enabled: true,
                                    },
                                },
                                plotOptions: {
                                    radialBar: {
                                        startAngle: -90,
                                        endAngle: 90,
                                        track: {
                                            background: '#e7e7e7',
                                            strokeWidth: '97%',
                                            margin: 5, // margin is in pixels
                                            dropShadow: {
                                                enabled: true,
                                                top: 2,
                                                left: 0,
                                                color: '#999',
                                                opacity: 1,
                                                blur: 2,
                                            },
                                        },
                                        dataLabels: {
                                            name: {
                                                show: false,
                                            },
                                            value: {
                                                offsetY: -2,
                                                fontSize: '22px',
                                            },
                                        },
                                    },
                                },
                                grid: {
                                    padding: {
                                        top: -10,
                                    },
                                },
                                fill: {
                                    type: 'gradient',
                                    gradient: {
                                        shade: 'light',
                                        shadeIntensity: 0.4,
                                        inverseColors: false,
                                        opacityFrom: 1,
                                        opacityTo: 1,
                                        stops: [0, 50, 53, 91],
                                    },
                                },
                                labels: ['Average Results'],
                            },
                        });
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    const [isLoader, setIsLoader] = useState(false);
    const [activityData, setActivityData] = useState<any>([]);
    const colors = ['info', 'success', 'warning', 'secondary', 'primary'];

    function getActivityLogList(
        studentDetailsId: any,
        fromDateInMilliSec: any,
        toDateInMilliSec: any,
    ) {
        getStudentActivityLog(
            studentDetailsId,
            fromDateInMilliSec,
            toDateInMilliSec,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentActivityLog;
                    if (data != undefined) {
                        for (let i = 0; i < data.length; i++) {
                            let activities = data[i].activities;
                            for (let i = 0; i < activities.length; i++) {
                                activities[i].color =
                                    colors[Math.floor(Math.random() * colors.length)];
                            }
                        }
                        setActivityData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    // toasts(response.data.message, 'Error');
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    return (
        <PageWrapper title='Schedule'>
            <Page container='fluid'>
                <div className='row'>
                    <div className='col-12'>
                        <Card stretch className='border border-light rounded-1' shadow={'md'}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='Schedule' iconColor='info' className='col-lg-5'>
                                    <CardTitle tag='div' className='h5'>
                                        Schedule
                                    </CardTitle>
                                </CardLabel>
                                &nbsp;&nbsp;
                                <CardActions className='d-print-none'>
                                    <span className='text-success fs-5'>
                                        <b>{dayOrder}</b>
                                    </span>
                                </CardActions>
                            </CardHeader>
                            <CardBody>
                                {dataStatus ? (
                                    <>
                                        {scheduleData != '' && dayId > 0 && dayId != 7 ? (
                                            <div className='row'>
                                                {scheduleData.map((period: any) => (
                                                    <div
                                                        className='col-4'
                                                        key={period.periodDetailsId}>
                                                        <Card
                                                            stretch
                                                            shadow={'md'}
                                                            className='border border-light'>
                                                            <CardBody>
                                                                <div className='row align-items-center'>
                                                                    <div className='col d-flex align-items-center'>
                                                                        <div className='flex-shrink-0'>
                                                                            <div
                                                                                className='ratio ratio-1x1'
                                                                                style={{
                                                                                    width: 65,
                                                                                }}>
                                                                                <div
                                                                                    className={classNames(
                                                                                        'rounded-2',
                                                                                        'd-flex align-items-center justify-content-center',
                                                                                        {
                                                                                            'bg-l10-info':
                                                                                                !darkModeStatus,
                                                                                            'bg-lo25-info':
                                                                                                darkModeStatus,
                                                                                        },
                                                                                    )}>
                                                                                    <span className='fw-bold'>
                                                                                        <span
                                                                                            className='text-info'
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    '23px',
                                                                                            }}>
                                                                                            {
                                                                                                period.periodCount
                                                                                            }
                                                                                        </span>
                                                                                        {/* <br />
																					<small className='text-dark' style={{ fontSize: '10px' }}>
																						period
																					</small> */}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex-grow-1 ms-2 d-flex justify-content-between align-items-center'>
                                                                            <div>
                                                                                <small className='fw-bold mb-0'>
                                                                                    {
                                                                                        period.paperName
                                                                                    }
                                                                                </small>
                                                                                <div className=' text-info fs-6 mb-0'>
                                                                                    {
                                                                                        period.staffName
                                                                                    }
                                                                                </div>
                                                                                <div className='text-info'>
                                                                                    <small>
                                                                                        <span className='text-black-50 fw-bold'>
                                                                                            {
                                                                                                period.startTime
                                                                                            }
                                                                                            -
                                                                                            {
                                                                                                period.endTime
                                                                                            }
                                                                                        </span>
                                                                                    </small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : scheduleData != '' && (dayId == 0 || dayId == 7) ? (
                                            <div className='col-12' style={{ height: '61vh' }}>
                                                <div className='row'>
                                                    <div
                                                        className='col-12'
                                                        style={{ height: '15vh' }}></div>
                                                    <div className='col-12 d-flex justify-content-center'>
                                                        <Icon icon='CalendarToday' size={'10x'} />
                                                    </div>
                                                    <div className='fs-4 fw-bold d-flex justify-content-center text-center text-uppercase text-danger'>
                                                        Holiday
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='row'>
                                                {/* <div className='col-12 d-flex justify-content-center'>
                                                    <img
                                                        srcSet={HumansWebp}
                                                        src={Humans}
                                                        alt='Humans'
                                                        style={{ height: '50vh' }}
                                                    />
                                                </div> */}
                                                <div className='fs-4 fw-bold d-flex justify-content-center text-center text-uppercase text-danger'>
                                                    Schedule Not Allocated
                                                </div>
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                            </div>
                                        )}
                                    </>
                                ) : null}
                            </CardBody>
                        </Card>
                    </div>
                    <div className='col-xl-6'>
                        <Circular />
                    </div>
                    <div className='col-xl-6'>
                        <DashboardEventList />
                    </div>
                    <div className='col-xl-6'>
                        <DashboardReminder />
                    </div>
                </div>
            </Page>
        </PageWrapper>
    );
};

export default ParentDashboard;
