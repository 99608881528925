import React, { useContext, useEffect, useRef, useState } from 'react'
import Card, { CardHeader, CardLabel, CardTitle, CardBody } from '../../../../components/bootstrap/Card'
import Avatar from '../../../../components/Avatar'
import classNames from 'classnames'
import useDarkMode from '../../../../hooks/useDarkMode'
import Popovers from '../../../../components/bootstrap/Popovers'
import Chart from '../../../../components/extras/Chart'
import Button from '../../../../components/bootstrap/Button'
import { Link } from 'react-router-dom'
import { profilePic, updateFilter } from '../../../../services/common.service'
import { useFormik } from 'formik'
import { dataPagination } from '../../../../components/PaginationButtons'
import AuthContext from '../../../../contexts/authContext'
import useSortableData from '../../../../hooks/useSortableData'
import { getLicenseKey } from '../../../../services/application.settings'
import { showLoader } from '../../../../services/loader.services'
import { getStaffLeaveDetails, updateStaffLeave, getStatus } from '../../../../services/staff.service'
import { toasts } from '../../../../services/toast.service'
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../../../components/bootstrap/Dropdown'
import Modal, { ModalBody, ModalHeader } from '../../../../components/bootstrap/Modal'
import AlertService from '../../../../services/AlertService'
import Alert from '../../../../components/bootstrap/Alert'

const StaffLeaveReqestList = () => {

    const { userAccountId, userTypeId } = useContext(AuthContext);

    useEffect(() => {
        getStaffLeave(0, 0);
        getStatusList()
    }, []);

    const editLeaveForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            staffDetailsId: '',
            fromDate: '',
            toDate: '',
            totalDays: '',
            reason: '',
            approvalStaff: '',
            remarks: '',
            statusId: '',
            leaveTypeId: '',
            staffLeaveDetailsId: '',
        },
        validate: (values) => {
            const errors: {
                staffDetailsId?: string;
                fromDate?: string;
                reason?: string;
                toDate?: string;
                totalDays?: string;
                approvalStaff?: string;
                remarks?: string;
                statusId?: string;
                leaveTypeId?: string;
            } = {};

            if (userTypeId != 1) {
                if (!staffDetailsIdForAdd) {
                    errors.staffDetailsId = 'Required';
                }
            }
            if (!fromDate) {
                errors.fromDate = 'Required';
            }
            if (!toDate) {
                errors.toDate = 'Required';
            }
            if (!values.totalDays) {
                errors.totalDays = '';
            }
            if (!values.reason) {
                errors.reason = 'Required';
            }
            if (statusId?.value != 1) {
                if (!values.remarks) {
                    errors.remarks = 'Required';
                }
            }
            if (!approvalStaff) {
                errors.approvalStaff = 'Required';
            }
            if (!statusId) {
                errors.statusId = 'Required';
            }
            if (!leaveTypeId) {
                errors.leaveTypeId = 'Required';
            }
            return errors;
        },
        // validateOnChange: true,
        onSubmit: () => {
            updateStaffLeaveSubmit();
        },
    });

    const { themeStatus, darkModeStatus } = useDarkMode();
    const [fromDate, setFromDate] = useState<any>('');
    const [toDate, setToDate] = useState<any>('');
    const [staffDetailsId, setStaffDetailsId] = useState<any>('');
    const [leaveData, setLeaveData] = useState<any>([]);
    const [statusData, setStatusData] = useState<any>([]);
    const [staffDetailsIdForAdd, setStaffDetailsIdForAdd] = useState<any>('');
    const [approvalStaff, setApprovalStaff] = useState<any>('');
    const [statusId, setStatusId] = useState<any>('');
    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
    const [isOpen, setIsOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [leaveTypeId, setLeaveTypeId] = useState<any>(Number);
    const [isLoader, setIsLoader] = useState<any>(false);
    const [noDataMsg, setNoDataMsg] = useState<any>('');

    function getStaffLeave(userTypeId: any, staffDetailsId: any) {
        setIsLoader(true);
        getStaffLeaveDetails(
            userTypeId,
            staffDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffLeaveDetails;
                    if (data != undefined) {

                        const leaveData = data.filter((item: any) => item.statusId == 1)

                        for (let i = 0; i < leaveData.length; i++) {
                            leaveData[i].sno = i + 1;
                            leaveData[i].state = {
                                series: [leaveData[i].presentPercentage],
                                options: {
                                    chart: {
                                        type: 'radialBar',
                                        width: 50,
                                        height: 50,
                                        sparkline: {
                                            enabled: true,
                                        },
                                    },
                                    dataLabels: {
                                        enabled: false,
                                    },
                                    plotOptions: {
                                        radialBar: {
                                            hollow: {
                                                margin: 0,
                                                size: '50%',
                                            },
                                            track: {
                                                margin: 0,
                                            },
                                            dataLabels: {
                                                show: false,
                                            },
                                        },
                                    },
                                    stroke: {
                                        lineCap: 'round',
                                    },
                                    colors: [leaveData[i].presentPercentage > 90 ? process.env.REACT_APP_SUCCESS_COLOR : leaveData[i].presentPercentage > 50 && leaveData[i].presentPercentage < 90 ? process.env.REACT_APP_PRIMARY_COLOR : leaveData[i].presentPercentage < 35 ? process.env.REACT_APP_DANGER_COLOR : process.env.REACT_APP_WARNING_COLOR],
                                },
                            }
                        }

                        setLeaveData(leaveData);                       

                        if (leaveData == '') {
                            setNoDataMsg('No Data Found');
                        }
                    } else {
                        setIsLoader(false);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setLeaveData([]);
                    setIsLoader(false);
                    setNoDataMsg(response.data.message);
                }
            },
            (error) => {
                setIsLoader(false);
                toasts(error, 'Error');
            },
        );
    }

    function setUpdateStaffLeaveDetails() {
        return {
            staffDetailsId: staffDetailsId,
            leaveTypeId: leaveTypeId,
            assignedBy: approvalStaff,
            statusId: statusId,
            fromDate: fromDate,
            toDate: toDate,
            reason: reason,
            remarks: remarks,
            staffLeaveDetailsId: staffLeaveDetailsId,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        };
    }

    function updateStaffLeaveSubmit() {
        showLoader(true);
        if (editLeaveForm.isValid) {
            let leavePostData = setUpdateStaffLeaveDetails();
            updateStaffLeave(
                leavePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: 'success' });
                        setIsOpen(true);
                        setIsDeleteOpen(false)
                        getStaffLeave(0, 0);
                        resetForm();
                    } else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: 'warning' });
                        setIsOpen(true);
                    } else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: 'error' });
                        setIsOpen(true);
                    }
                },
                (error) => {
                    setAlertStatus({ message: error, type: 'error' });
                    setIsOpen(true);
                },
            );
        } else if (editLeaveForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: 'warning' });
            setIsOpen(true);
        }
    }

    function getStatusList() {
        getStatus(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.status;
                    if (data != undefined) {
                        setStatusData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setStatusData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function resetForm() {
        setStaffDetailsIdForAdd('');
        setApprovalStaff('');
        setLeaveTypeId('');
        setFromDate('');
        setToDate('');
        setStatusId('');
        editLeaveForm.resetForm();
    }

    const [staffLeaveDetailsId, setStaffLeaveDetailsId] = useState<any>('')
    const [reason, setReason] = useState<any>('')
    const [remarks, setRemarks] = useState<any>('')
    const [statusName, setStatusName] = useState<any>('')

    function selectStatus(status: any, i: any) {
        setIsDeleteOpen(true)
        setLeaveTypeId(i.leaveTypeId)
        setApprovalStaff(i.assignedBy)
        setFromDate(i.fromDate)
        setToDate(i.toDate)
        setStaffLeaveDetailsId(i.staffLeaveDetailsId)
        setReason(i.reason)
        setStaffDetailsId(i.staffDetailsId)
        setStatusName(status.statusName)
        setStatusId(status.statusId)
    }

    return (
        <>
            <Card
                stretch
                className='overflow-hidden border border-light rounded-1'
                shadow='md'
                tag='form'
                noValidate>
                <CardHeader borderSize={1}>
                    <CardLabel icon='Chat' iconColor='info'>
                        <CardTitle tag='div' className='h5'>
                            Staff Leave Request
                        </CardTitle>
                    </CardLabel>
                </CardHeader>
                <CardBody style={{ height: '32vh' }} isScrollable>
                    {leaveData != '' ?
                        <div className='col-12'>
                            {leaveData.map((i: any, index: any) => (
                                <div className={`row g-2 ${index > 0 ? 'mt-2' : ''}`}>
                                    <div className='col d-flex'>
                                        <div className='flex-shrink-0'>
                                            {i.profilePath != null ? (
                                                <Avatar
                                                    src={i.profilePath}
                                                    size={36}
                                                />
                                            ) : (
                                                <img
                                                    src={profilePic(i.genderId)}
                                                    width='36'
                                                    height='36'
                                                />
                                            )}
                                        </div>
                                        <div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
                                            <div>
                                                <Link
                                                    to={`../academic/addStaffLeave/${i.userTypeId}/${i.staffDetailsId}`}
                                                    className={classNames('fw-bold fs-6 mb-0', { 'link-dark': !darkModeStatus, 'link-light': darkModeStatus })}>
                                                    {i.staffName}
                                                </Link> - <small className='text-muted' style={{ fontSize: '12px' }}>{i.empNumber}</small>
                                                <div className='text-muted mt-n1'>
                                                    <small>{i.reason}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-auto'>
                                        <div className='d-flex align-items-center'>
                                            <Popovers desc='Remaining time' trigger='hover'>
                                                <span className='me-3'>%{i.presentPercentage}</span>
                                            </Popovers>
                                            <Chart
                                                series={i.state.series}
                                                options={i.state.options}
                                                type={i.state.options.chart?.type}
                                                height={i.state.options.chart?.height}
                                                width={i.state.options.chart?.width}
                                                className='me-3'
                                            />
                                            <Dropdown>
                                                <DropdownToggle hasIcon={false}>
                                                    <Button
                                                        color={darkModeStatus ? 'light' : 'dark'}
                                                        isLink
                                                        hoverShadow='default'
                                                        icon='MoreHoriz'
                                                        aria-label='More Actions'
                                                    />
                                                </DropdownToggle>
                                                <DropdownMenu isAlignmentEnd>
                                                    {statusData.map((item: any) => (
                                                        <DropdownItem>
                                                            <Button icon='Status' onClick={() => selectStatus(item, i)}>
                                                                {item.statusName}
                                                            </Button>
                                                        </DropdownItem>
                                                    ))}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div> :
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <b className='text-danger text-uppercase'>{noDataMsg}</b>
                            </div>
                        </div>
                    }
                </CardBody>
            </Card>

            <Modal
                setIsOpen={setIsDeleteOpen}
                isOpen={isDeleteOpen}
                titleId='deleteBatchMaster'
                isStaticBackdrop
                size='sm'>
                <ModalHeader><></></ModalHeader>
                <ModalBody>
                    <div className="row g-2">
                        <div className="col-12 d-flex justify-content-center fs-4">
                            <h6><strong>Are you sure want to {statusName} ?</strong></h6>
                        </div>
                        <div className='col-10'>
                            <div className='d-flex gap-2 justify-content-end'>
                                <Button color='danger' size={'sm'} onClick={updateStaffLeaveSubmit}>Yes</Button>
                                <Button color='info' size={'sm'} onClick={() => setIsDeleteOpen(!isDeleteOpen)}>No</Button>
                            </div>
                        </div>
                        <div className='col-10'></div>
                    </div>
                </ModalBody>
            </Modal>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </>
    )
}

export default StaffLeaveReqestList