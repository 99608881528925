import React, { useRef, useState } from 'react';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Card from '../../../bootstrap/Card';
import Icon from '../../../icon/Icon';
import CollegeLogo from '../../../../assets/logos/logo.jpg';
import signature from '../../../../assets/img/signature.png';
import seal from '../../../../assets/img/seal.png';
import Button from '../../../bootstrap/Button';
import { useReactToPrint } from 'react-to-print';

const Receipt = () => {
	const tableFontSize = 'fs-4';

	const componentRef = useRef(null);
	const data = [
		{ sr: 1, particulars: 'Computer Fees', amount: 200 },
		{ sr: 2, particulars: 'Term Fees', amount: 200 },
		{ sr: 3, particulars: 'Language Proficiency Class', amount: 300 },
		{ sr: 4, particulars: 'Tuition Fees', amount: 1300 },
	];

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<PageWrapper title='Receipt'>
			<Page container='fluid'>
				<div className='d-flex col-7 justify-content-end' style={{ marginLeft: '440px' }}>
					<Button icon='Print' isLight color='primary' onClick={handlePrint}>
						Print
					</Button>
				</div>
				<Card
					ref={componentRef}
					className='rounded-0 mt-3'
					style={{ border: '1px solid black', fontFamily: 'Times New Roman' }}>
					<table>
						<table
							cellPadding={10}
							cellSpacing={10}
							width='90%'
							style={{ fontFamily: 'Times New Roman' }}>
							<tbody>
								<tr>
									<td align='center' width='12%'>
										<img height='100px' width='100px' src={CollegeLogo} />
									</td>
									<td
										colSpan={1}
										className='text-center'
										align='right'
										style={{
											fontFamily: 'Bookman Old Style',
											// width: '40%',
										}}>
										<h1 className='text-uppercase'>
											<b style={{ fontSize: '90%' }}>
												{' '}
												Surya International School
											</b>
										</h1>

										<p className='fs-6 fw-bold'>
											(Permanent Affiliated to NAAC, India)
											<br />
											Vettalam Rd,keeroor(v), Dindigul (TK), Dindigul, 624001,
											<br />
											<Icon icon='Email' />{' '}
											https://school-dev.bluebottless.com{' '}
											<Icon icon='Phone' /> 8428614298{' '}
											<Icon icon='PhoneAndroid' /> 7695869984
										</p>
									</td>
								</tr>
							</tbody>
						</table>
						<hr />
						<tbody>
							<div className='col-12 mt-3'>
								<div className='row'>
									<div className='col-4'>
										<p className='ms-3 fs-4'>
											<strong>Receipt No :</strong> 1
										</p>
										<p className='ms-3 fs-4'>
											<strong>Student Name :</strong> Manoj Kumar{' '}
										</p>
									</div>
									<div className='col-6 fs-4'>
										<p style={{ marginLeft: '110px' }}>
											<strong>Date :</strong> 15-06-2020
										</p>
									</div>
									<div className='col-2'>
										<p className='text-start fs-4'>
											<strong>Roll No :</strong> PC14102
										</p>
										<p className='text-start fs-4'>
											<strong>Class :</strong> IV - B
										</p>
									</div>
								</div>
							</div>
						</tbody>
					</table>

					<table className='ms-2' style={{ width: '99%', borderCollapse: 'collapse' }}>
						<thead>
							<tr className={tableFontSize}>
								<th style={{ border: '1px solid black', padding: '8px' }}>S.No</th>
								<th style={{ border: '1px solid black', padding: '8px' }}>
									Particulars
								</th>
								<th style={{ border: '1px solid black', padding: '8px' }}>
									Amount
								</th>
							</tr>
						</thead>
						<tbody className={tableFontSize}>
							{data.map((item) => (
								<tr key={item.sr}>
									<td style={{ border: '1px solid black', padding: '8px' }}>
										{item.sr}
									</td>
									<td style={{ border: '1px solid black', padding: '8px' }}>
										{item.particulars}
									</td>
									<td style={{ border: '1px solid black', padding: '8px' }}>
										{item.amount}
									</td>
								</tr>
							))}
						</tbody>
					</table>

					<tbody className='mt-3'>
						<div className='col-12'>
							<div className='row'>
								<div className='col-4'>
									<p className='ms-3 mt-2 fs-5'>
										<strong>Amount In Words :</strong> Two Thousand Only /-
									</p>
								</div>
								<div className='col-6'>
									<p style={{ marginLeft: '110px' }}></p>
								</div>
								<div className='col-2'>
									<p className='text-start mt-2 fs-5'>
										<strong>Total Amount :</strong> 2000
									</p>
								</div>
							</div>
						</div>
						<hr />

						{/* <img
							height='100px'
							width='100px'
							src={signature}
							style={{
								marginLeft: '55px',
								background: 'transparent',
							}}
						/>

						<img
							height='120px'
							width='120px'
							src={seal}
							style={{
								marginTop: '10px',
								marginLeft: '760px',
								background: 'transparent',
							}}
						/> */}

						{/* <div className='col-12'>
							<div className='row'>
								<div className='col-1 mt-3'></div>
								<div className='col-4 mt-1'>
									<p>
										<strong>Seal</strong>
									</p>
								</div>
								<div className='col-4'>
									<p style={{ marginLeft: '100px' }}></p>
								</div>

								<div className='col-3'>
									<p className='text-center'>
										<strong>Signature</strong>
									</p>
								</div>
							</div>
						</div> */}
					</tbody>

					<div className='col-12'>
						<div className='row'>
							<div className='col-2 text-center'>
								<img
									style={{ borderRadius: '50%' }}
									src={signature}
									width='120'
									height='120'
								/>
								<br />
								<span className='fs-4 fw-bold text-uppercase'>Seal</span>
							</div>
							<div className='col-8'></div>
							<div className='col-2'>
								<img
									style={{ borderRadius: '50%' }}
									src={seal}
									width='120'
									height='120'
								/>
								<br />
								<span className='fs-4 fw-bold ms-3 text-uppercase'>Signature</span>
							</div>
						</div>
					</div>
					<br />
				</Card>
			</Page>
		</PageWrapper>
	);
};

export default Receipt;
