import classNames from 'classnames'
import Payment from 'payment'
import React, { useContext, useEffect, useState } from 'react'
import Button from './bootstrap/Button'
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody, CardFooter, CardFooterLeft } from './bootstrap/Card'
import useDarkMode from '../hooks/useDarkMode'
import ReactCreditCardsContainer from './extras/ReactCreditCardsContainer'
import { getLicenseKey, webUrl } from '../services/application.settings'
import PageWrapper from '../layout/PageWrapper/PageWrapper'
import Page from '../layout/Page/Page'
import { toasts } from '../services/toast.service'
import { getReceiptTemplate, updateReceiptTemplate } from '../services/common.service'
import { showLoader } from '../services/loader.services'
import AuthContext from '../contexts/authContext'
import AlertService from '../services/AlertService'

const ReceiptTemplate = () => {

    useEffect(() => {
        getReceiptTemplateList()
    }, [])

    const { darkModeStatus } = useDarkMode();
    const { userAccountId, userTypeId } = useContext(AuthContext);

    const [cardList, setCardList] = useState<any>([]);
    const [noDataMsg, setNoDataMsg] = useState<any>('');
    const [receiptTemplateId, setReceiptTemplateId] = useState<any>('');
    const [templatePath, setTemplatePath] = useState<any>('');

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    function selectedTemplate(e: any) {
        setReceiptTemplateId(e.receiptTemplateId)
        setTemplatePath(e.imagePath)
    }

    function getReceiptTemplateList() {
        getReceiptTemplate(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.receiptTemplate;
                    if (data != undefined) {
                        setCardList(data);

                        let activeTemplate = data.filter((item: any) => item.isActive == true)
                        setReceiptTemplateId(activeTemplate[0]?.receiptTemplateId)
                        setTemplatePath(activeTemplate[0]?.imagePath)

                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setCardList([]);
                    setNoDataMsg(response.data.message)
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setEditAcademicPeriodDetails() {
        return ({
            licenseKey: getLicenseKey,
            staffDetailsId: userAccountId,
            receiptTemplateId: receiptTemplateId
        })
    }

    function updateReceiptTemplateSubmit() {
        showLoader(true)
        if (receiptTemplateId > 0) {
            let templatePostData = setEditAcademicPeriodDetails()
            updateReceiptTemplate(templatePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getReceiptTemplateList();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (receiptTemplateId == 0) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }


    return (
        <PageWrapper title='Route Master'>
            <Page container='fluid'>
                <div className='row px-4'>
                    <div className='col-xl-3 '>
                        <Card stretch>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='Style' iconColor='primary'>
                                    <CardTitle>
                                        Receipts
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className='row g-3'>
                                    {cardList.map((c: any) => (
                                        <div key={c.receiptTemplateId} className='col-12'>
                                            <Button
                                                icon={c.isActive ? 'CheckCircleOutline' : 'Article'}
                                                color={c.isActive ? 'success' : 'info'}
                                                className='w-100 p-3'
                                                isLight={
                                                    darkModeStatus
                                                        ? c.receiptTemplateId == receiptTemplateId
                                                        : c.receiptTemplateId != receiptTemplateId
                                                }
                                                onClick={() => selectedTemplate(c)}>
                                                {c.templateName}
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                            </CardBody>
                            <CardFooter borderSize={1}>
                                <CardFooterLeft className='w-100'>
                                    <Button
                                        icon='Save'
                                        color='primary'
                                        className='w-100 p-3'
                                        onClick={updateReceiptTemplateSubmit}>
                                        Update
                                    </Button>
                                </CardFooterLeft>
                            </CardFooter>
                        </Card>
                    </div>
                    <div className='col-xl-9'>
                        <Card stretch>
                            <CardBody>
                                {templatePath != '' ?
                                    <div className='col-12'>
                                        <img src={templatePath} width={765} height={420} />
                                    </div> : null
                                }
                            </CardBody>
                        </Card>
                    </div>
                </div>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

            </Page>
        </PageWrapper>
    );
};

export default ReceiptTemplate