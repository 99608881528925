import React, { useContext, useEffect, useState } from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderChat from './CommonHeaderChat';
import Search from '../../../components/Search';
import CommonHeaderRight from './CommonHeaderRight';
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import { toasts } from '../../../services/toast.service';
import { getProfileForStudent } from '../../../services/student.service';

const DashboardHeader = () => {
	const { darkModeStatus } = useDarkMode();
	const { userTypeId, userAccountId } = useContext(AuthContext);
	const [userData, setUserData] = useState<any>({});

	useEffect(() => {
		if (userTypeId == 9 || userTypeId == 10) {
			// getStudentProfile(userAccountId)
		}
	}, []);

	function getStudentProfile(studentDetailsId: number) {
        getProfileForStudent(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile;
                    if (data != undefined) {
                        setUserData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

	return (
		<Header>
			<HeaderLeft>
				{userTypeId != 9 ? <Search /> :
					<div className='d-flex align-items-center'>
						<div className='row g-4'>
							<div className='col-md-auto'>
								<div
									className={classNames('fs-3', 'fw-bold', {
										'text-dark': !darkModeStatus,
									})}>
									Hi, {userData.studentName}!
								</div>
							</div>
						</div>
					</div>
				}

			</HeaderLeft>
			<CommonHeaderRight afterChildren={<CommonHeaderChat />} />
		</Header>
	);
};

export default DashboardHeader;
